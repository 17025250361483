/* Styles */
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import { ButtonWsp } from './ButtonWsp';
import { ScrollTop } from './ScrollTop';


import { Translation } from './Translation';
import { useTranslation } from 'react-i18next';



const Footer = () => {

  const { t } = useTranslation();
  return (
    <>

      <footer className="pt-5">
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-4">
              <img className="img-fluid mb-5" src="https://ucarecdn.com/dc4ad9da-0aca-441a-bf5a-d33bf6cad63e/logo_footer.png" alt="Logo Footer"></img>
            </div>
            <div className="col-lg-4">
              <ul className="nav-footer list-inline">
                <li className="mt-2">
                  <Link
                    className="nav-link-footer"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    className="nav-link-footer"
                    to="/about-the-tip"
                  >
                    {t('menu.about')}
                  </Link>
                </li>
                <li className="mt-2">
                  <a className="nav-link-footer" href="https://tipmethodcourses.net/producto/conviertete-en-miembro-de-tip/" target="_blank" rel="noreferrer">{t('menu.members')}</a>
                </li>


                <li className="mt-2">
                  <Link
                    className="nav-link-footer"
                    to="/books"
                  >
                    {t('menu.books')}
                  </Link>
                </li>
                <li className="mt-2">
                  <Link className="nav-link-footer"
                    to="/education"
                  >
                    {t('menu.campus')}
                  </Link>
                </li>
                
                <li className="mt-2">
                  <a className="nav-link-footer" href="https://tipmarketplace.net/" target="_blank" rel="noreferrer">{t('menu.find')}</a>
                </li>

                <li className="mt-2">
                  <Link
                    className="nav-link-footer"
                    to="/contact-us"
                  >
                    {t('menu.contact')}
                  </Link>
                </li>

              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="list-inline">
                <li><h4><strong>Things In Place, LLC</strong></h4></li>
                <li className="mt-3">Things In Place, LLC</li>
                <li>111 W. Jackson Blvd.</li>
                <li>Suite 1700</li>
                <li>Chicago, IL 60604</li>
                <li><a href="https://api.whatsapp.com/send?phone=+13125220912&text=%C2%A1Hola%21+Quiero+m%C3%A1s+informaci%C3%B3n" target="_blank" rel="noreferrer"><img className="p-1 icon-phone-footer" src="https://ucarecdn.com/ee85a0ae-eab5-44b3-919c-c8f3c6b3a6a8/wpp.png" alt="img-wpp" />+1 (312) 522-0912</a></li>
                <li><a href="tel: +1 (312) 376-4048"><img className="p-1 icon-phone-footer icon-white" src="https://ucarecdn.com/fe0bdc03-23b4-45b0-82a0-657f435375df/phonesymbol2.png" alt="img-wpp" />+1 (312) 376-4048</a></li>
              </ul>
              <ul className="list-inline">
                <li><h4 className="text-uppercase"><strong>rrss</strong></h4></li>
                <li className="img-socials mt-3 mb-5">
                  <a href="https://www.instagram.com/tip.method/" target="_blank" rel="noreferrer"><img src="https://ucarecdn.com/2c713767-a7ed-48ca-ae8c-b31b72260e5a/instagram.png" alt="img-ig" /></a>
                  <a href="https://www.facebook.com/TipMethod" target="_blank" rel="noreferrer"><img className="mx-2" src="https://ucarecdn.com/aeed920f-3850-434f-a7b5-442cfd293e1d/facebook.png" alt="img-fb" /></a>
                  <a href="https://api.whatsapp.com/send?phone=2245178223&text=%C2%A1Hola%21+Quiero+m%C3%A1s+informaci%C3%B3n" target="_blank" rel="noreferrer"><img className="p-1" src="https://ucarecdn.com/ee85a0ae-eab5-44b3-919c-c8f3c6b3a6a8/wpp.png" alt="img-wpp" /></a>
                  <a href="mailto: admissions@tipmethod.net" target="_blank" rel="noreferrer"><img className="mx-2" src="https://ucarecdn.com/b5603f13-3e5c-4cac-b24e-9711870b4b5a/mail.png" alt="img-mail" /></a>
                </li>
              </ul>
              <Translation />
            </div>
          </div>
          {/* <hr />
          <p className="text-center">{t('quote-copy')}</p> */}
        </div>
        <ButtonWsp />
        <ScrollTop />
      </footer>
    </>
  )
}

export default Footer