export const modules = [
    {
        'id': 'mod-1',
        'key': 'm1',
        'title': 'educacion.modulos.1-module-title',
        'paragraph': 'educacion.modulos.1-module-paragraph'
    },
    {
        'id': 'mod-2',
        'key': 'm2',
        'title': 'educacion.modulos.2-module-title',
        'paragraph': 'educacion.modulos.2-module-paragraph'
    },
    {
        'id': 'mod-3',
        'key': 'm3',
        'title': 'educacion.modulos.3-module-title',
        'paragraph': 'educacion.modulos.3-module-paragraph'
    },
    {
        'id': 'mod-4',
        'key': 'm4',
        'title': 'educacion.modulos.4-module-title',
        'paragraph': 'educacion.modulos.4-module-paragraph'
    },
    {
        'id': 'mod-5',
        'key': 'm5',
        'title': 'educacion.modulos.5-module-title',
        'paragraph': 'educacion.modulos.5-module-paragraph'
    },
    {
        'id': 'mod-6',
        'key': 'm6',
        'title': 'educacion.modulos.6-module-title',
        'paragraph': 'educacion.modulos.6-module-paragraph'
    },
    {
        'id': 'mod-7',
        'key': 'm7',
        'title': 'educacion.modulos.7-module-title',
        'paragraph': 'educacion.modulos.7-module-paragraph'
    },
    {
        'id': 'mod-8',
        'key': 'm8',
        'title': 'educacion.modulos.8-module-title',
        'paragraph': 'educacion.modulos.8-module-paragraph'
    },
    {
        'id': 'mod-9',
        'key': 'm9',
        'title': 'educacion.modulos.9-module-title',
        'paragraph': 'educacion.modulos.9-module-paragraph'
    },
    {
        'id': 'faq-10',
        'key': 'm10',
        'title': 'educacion.modulos.10-module-title',
        'paragraph': 'educacion.modulos.10-module-paragraph'
    },
    {
        'id': 'faq-11',
        'key': 'm11',
        'title': 'educacion.modulos.11-module-title',
        'paragraph': 'educacion.modulos.11-module-paragraph'
    }
]