import { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { useForm } from '../hooks/useForm';


export const ContactHero = () => {

    const { t } = useTranslation();

    const { formState, onInputChange, onResetForm, name, email, country, phone, message } = useForm({
        name: '',
        email: '',
        country: '',
        phone: '',
        message: '',
    });

    const [show, setShow] = useState(true);

    const [hideForm, setHideForm] = useState(true);

    function changeState() {
        setShow(!show);
        setHideForm(!show);
    }

    

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ukck7u8', 'template_brtkkrp', formState.current, 'vwSEFdSsXPM75Bn3x')
            .then((result) => {
                setShow(true);
                setTimeout(() => {
                    setHideForm(false); 
                    onResetForm();
                }, 100);
            }, (error) => {
                setShow(false);
                
                setTimeout(() => {
                    onResetForm();
                    setHideForm(false); 
                }, 100);
            });

    };

    




    return (
        <form ref={formState} onSubmit={sendEmail}>
            {
                hideForm
                    ?
                    (
                        <>
                            <div className="d-flex justify-content-between">
                                <div className="w-100 m-form">
                                    <label>{t('form.name')}</label>
                                    <input className="w-100" type="text" required placeholder={t('form.name')} name="name" onChange={onInputChange} value={name} />
                                </div>
                                <div className="w-100 m-form">
                                    <label>Email</label>
                                    <input className="w-100" type="email" required placeholder="Email" name="email" onChange={onInputChange} value={email} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="w-100 m-form">
                                    <label>{t('form.country')}</label>
                                    <input className="w-100" type="text" required placeholder={t('form.country')} name="country" onChange={onInputChange} value={country} />
                                </div>
                                <div className="w-100 m-form">
                                    <label>{t('form.phone')}</label>
                                    <input className="w-100" type="number" required placeholder={t('form.phone')} name="phone" onChange={onInputChange} value={phone} />
                                </div>
                            </div>
                            <div className="w-100 m-form">
                                <label>{t('form.message')}</label>
                                <textarea className="w-100" placeholder={t('form.message')} name="message" onChange={onInputChange} value={message} />
                            </div>
                            <div className="g-recaptcha" data-sitekey="6LcEBKUZAAAAAES-mvRyTD200oo8SD3F7ktJgyIG"></div>
                            {sendEmail.errors ? `${sendEmail.errors}` : null}

                            {show ? (
                                <>
                                    <button onClick={sendEmail} id="btn" >
                                        <p id="btnText">{t('form.send')}</p>
                                        <div className="check-box">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </div>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button onClick={changeState} id="btn" className={`active ${show ? "active" : ""}`}>
                                        <p id="btnText">{t('form.tnks')}</p>
                                        <div className="check-box">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </div>
                                    </button>
                                </>
                            )}
                            <p className="success"></p>
                        </>
                    )
                    :
                    (<h2 className="thnks-msg">{t('form.tnks')}</h2>)
            }
        </form>
    );
};