import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { getPlanners } from "../helpers/getPlanners"
import { useForm } from "../hooks/useForm";
import { getPlannersByCountry } from "../helpers/getPlannerByCountry";
import { CardTestimonial } from "../components/CardTestimonial";


import '../styles/InputSearch.css'


const FindTipPlanner = ({ active }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const { q = '' } = queryString.parse(location.search);
  const planners = getPlannersByCountry(q);

  const AllPlanners = getPlanners(active);

  const { searchText, onInputChange } = useForm({
    searchText: q,
  });

  const onSearchSubmit = e => {

    e.preventDefault();
    // if (searchText.trim().length <= 1) return;

    navigate(`?q=${searchText.toLowerCase().trim()}`);

  };


  return (
    <>
      <div className="container py-5">

        <form onSubmit={onSearchSubmit}>
          <div   className="search-box">
            <input
              type="text"
              placeholder="Search a Tip Planner by country"
              className="search-txt"
              name="searchText"
              autoComplete="off"
              value={searchText}
              onChange={onInputChange}
            />
            <strong className="search-btn">
              <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </strong>
          </div>

        </form>

        <div className="row justify-content-center mt-5">

          {

            q.length <= 6

              ?

              AllPlanners.map(({ id, img, name, country, certification }) => (
                <CardTestimonial
                   
                  classCols="col-6 col-lg-3 m-1 card-planner"
                  key={id}
                  image={img}
                  title={name}
                  subtitle={certification}
                  location={country}
                  urlBtn={`/find-tip-planner/${id}`}
                />
              ))

              :

              planners.map(({ id, img, name, country, certification }) => (
                <CardTestimonial
                   
                  classCols="col-6 col-lg-3 m-1"
                  key={id}
                  image={img}
                  title={name}
                  subtitle={certification}
                  location={country}
                />
              ))

          }

        </div>

      </div>
    </>
  )
}

export default FindTipPlanner