import { HeroSections } from "../components/HeroSections"
import { useTitle } from "../hooks/useTitle"
import { useTranslation } from 'react-i18next';
import { ContactFooter } from "../components/ContactFooter";


const ContactUs = () => {
  useTitle({ title: 'Contact Us' });
  const { t } = useTranslation();
  return (
    <>
      <HeroSections
        title={t('contact-us.title')}
      />
      <ContactFooter />
    </>
  )
}

export default ContactUs