/* Styles */
import '../styles/CardServices.css';
import { Button } from './Button';
export const CardTestimonial = ({ image, title, subtitle, location, classCols, urlBtn, txtBtn }) => {
  return (
    <article className={ `card-services ${ classCols }` }>
      <img src={ image } alt={ title }/>
      <div className="card-services-content">
        <div className="card-body-text">
          <h4>{ subtitle }</h4>
          <h3>{ title }</h3>
          { location ? <p className={ location ? 'd-block' : 'd-none'}  >{ location }</p> : null }
        </div>
        { urlBtn ? <div><Button url={ urlBtn } text={ txtBtn } target="_blank" /> </div> : null }
      </div>
    </article>
  )
}
