import { AccordionItem } from '../components/AccordionItem';
import { Descriptions } from '../components/Descriptions';
import { HeroSections } from '../components/HeroSections';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Button';
import { useTitle } from '../hooks/useTitle';
import { Carousel } from '../components/Carousel';
import { VideoJS } from '../components/VideoJS';
import { ContactFooter } from '../components/ContactFooter';




const AboutTheTip = () => {

  useTitle({ title: 'Education' });
  const { t } = useTranslation();

  return (
    <>
      <HeroSections
        sectionTitle="educacion.quote"
        title="educacion.title"
      />
      <Descriptions
        quoteDivider="educacion.quote-divider-1"
        titleDescription="educacion.quote-title-1"
        paragraphDescription="educacion.quote-paragraph-1"
      />
      <Descriptions
        quoteDivider="educacion.quote-divider-2"
        titleDescription="services.title-1"
        paragraphDescription="educacion.quote-paragraph-2"
      />
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-2">
            <h3   className="text-uppercase p-ttl-sticky pt-3"><strong>{t('educacion.quote-divider-3')}</strong></h3>
          </div>
          <div className="col-lg-10"  >
            <AccordionItem />
          </div>
        </div>
        {/* <div className="container">
          <h3   className="separator-section">certificación tip</h3>
          <VideoJS />
        </div> */}
        <div className="row mt-5">
          <div className="col-lg-2">
            <h3   className="text-uppercase p-ttl-sticky pt-3"><strong>{t('educacion.start.quote-divider-2')}</strong></h3>
          </div>
          <div className="col-lg-10">
            <div className="accordion" id="accordion"  >
              <div className="accordion-item">
                <h2 className="accordion-header" id="a1">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#b1" aria-expanded="false" aria-controls="b1">
                    {t('educacion.start.modulos.1-module-title')}
                  </button>
                </h2>
                <div id="b1" className="accordion-collapse collapse" aria-labelledby="a1" data-bs-parent="#accordion">
                  <div className="accordion-body">
                    <p>{t('educacion.start.modulos.1-module-paragraph')}</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="a2">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#b2" aria-expanded="false" aria-controls="b2">
                    {t('educacion.start.modulos.2-module-title')}
                  </button>
                </h2>
                <div id="b2" className="accordion-collapse collapse" aria-labelledby="a2" data-bs-parent="#accordion">
                  <div className="accordion-body">
                    <p>{t('educacion.start.modulos.2-module-paragraph')}</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="a3">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#b3" aria-expanded="false" aria-controls="b3">
                    {t('educacion.start.modulos.3-module-title')}
                  </button>
                </h2>
                <div id="b3" className="accordion-collapse collapse" aria-labelledby="a3" data-bs-parent="#accordion">
                  <div className="accordion-body">
                    <p>{t('educacion.start.modulos.3-module-paragraph')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Carousel />
      <section className="mt-5 bg-quote-education bg-separator">
        <div className="bg-filter-education">
          <div className="container py-5">
            <div className="row">
              <article className="col-lg-3">
                <img src="https://ucarecdn.com/ca2fda4c-1c21-4624-b15e-503cfbbe718d/isc.png" className="img-fluid" alt="misc"></img>
              </article>
              <article   className="col-lg-3">
                <p>{t('quote-marie.quote')}</p>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-section py-5">
            <article>
                <div className="container">
                    <div className="row">
                        <div   className="col-lg-2">
                            <h3 className="p-ttl-sticky"><strong>{ t('planificar-organizar.quote-divider-3') }</strong></h3>
                        </div>
                        <div className="col-lg-10">
                          <h2   className="mt-3 mb-5">{ t('planificar-organizar.title') }</h2>
                          <p  >{ t('planificar-organizar.paragraph') }</p>
                        </div>
                        
                    </div>
                </div>
            </article>
        </section>
      
      <section className="redirections py-5">
        <div className="container">
          <div className="row border-b py-3">
            <article   className="col-lg-2">
              <h6 className="text-uppercase separator-section"><strong>{t('redirections-2.title')}</strong></h6>
            </article>
            <article   className="col-lg-8 py-4">
              <p className="text-uppercase">{t('redirections-2.paragraph')}</p>
            </article>
            <article   className="col-lg-2 my-auto">
              <Button url="https://tipmethodcourses.net/producto/conviertete-en-miembro-de-tip/"  target="_blank" text={t('redirections.educación.btn-quote')} />
            </article>
          </div>
          <div className="row border-b pt-5 pb-3">
            <article   className="col-lg-2">
              <h6 className="text-uppercase separator-section"><strong>{t('redirections-2.title-2')}</strong></h6>
            </article>
            <article   className="col-lg-8 py-4">
              <p className="text-uppercase">{t('redirections-2.paragraph-2')}</p>
            </article>
            <article   className="col-lg-2 my-auto">
              <Button url="/becas"  target="_blank"  text={t('redirections.educación.btn-quote')} />
            </article>
          </div>
        </div>
      </section>
      <ContactFooter />
    </>
  )
}

export default AboutTheTip