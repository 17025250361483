import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavbarBurger } from './NavbarBurger';
import { Translation } from './Translation';


const Navbar = () => {

    const { t } = useTranslation();

    return (
        <nav className="nav-desktop">
            <ul className="navbar-nav d-flex justify-content-between align-items-center flex-row">
                <li className="nav-item">
                    <Link
                        to="/"
                    >
                        <img className="img-fluid py-3" src="https://ucarecdn.com/3971073b-1e60-4c17-b4c9-2ca8559c3767/Logo.png" alt="img-logo" />
                    </Link>
                </li>
                <li className="nav-item dropdown hide-menu">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {t('menu.about')}
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li>
                            <Link className="dropdown-item hide-menu"
                                to="/about-the-tip"
                            >
                                {t('menu.about')}
                            </Link>
                        </li>
                        <li>
                            <a className="dropdown-item hide-menu" href="https://tipmethodcourses.net/producto/conviertete-en-miembro-de-tip/" target="_blank">{t('menu.members')}</a>
                        </li>
                    </ul>
                </li>
                <li className="nav-item dropdown hide-menu">
                    <a className="nav-link dropdown-toggle" href="/education" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {t('menu.education')}
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li>
                            <Link className="dropdown-item hide-menu"
                                to="/books"
                            >
                                {t('menu.books')}
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item hide-menu"
                                to="/education"
                            >
                                {t('menu.campus')}
                            </Link>
                        </li>
                        <li>
                            <Link className="dropdown-item hide-menu"
                                to="/levels-of-ip"
                            >
                                {t('menu.levels')}
                            </Link>
                        </li>
                        {/* <li>
                            <Link className="dropdown-item hide-menu"
                                to="/becas"
                            >
                                {t('menu.becas')}
                            </Link>
                        </li> */}
                    </ul>
                </li>
                <li className="nav-item">
                    <a className="hide-menu" href="https://tipmarketplace.net/" target="_blank">{t('menu.find')}</a>
                </li>
                
                <li className="nav-item">
                    <Link className="hide-menu"
                        to="/contact-us"
                    >
                        {t('menu.contact')}
                    </Link>
                </li>
                <li className="nav-item">
                    <Translation />
                </li>
                <li className="nav-item">
                    <NavbarBurger />
                </li>
            </ul>
        </nav>
    )
}

export default Navbar

