import { useTranslation } from 'react-i18next';

export const HeroSections = ({ sectionTitle, title, paragraph, job, name }) => {

    const { t } = useTranslation();
    
    return (
        <section className="hero-section py-5">
            <article>
                <div className="container">
                    <div className="row">
                        {sectionTitle ? <div   className="col-lg-2"><h3 className={sectionTitle ? 'p-ttl-sticky d-block' : 'd-none'}    >{t(`${sectionTitle}`)}</h3></div> : null}
                        <div className="col-lg-10">
                            {title ? <h2>{t(`${title}`)}</h2> : null}
                            {paragraph ? <p className={paragraph ? 'd-block text-justify mt-5 mb-3' : 'd-none'}    >{t(`${paragraph}`)}</p> : null}
                            {job ? <a href="https://www.mariettavitale.com/" target="_blank"><h6 className={job ? 'd-block' : 'd-none'}    >{t(`${job}`)}</h6></a> : null}
                            {name ? <a href="https://www.mariettavitale.com/" target="_blank"><h3 className={name ? 'd-block' : 'd-none'}    >{t(`${name}`)}</h3></a> : null}
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}
