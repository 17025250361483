export const faqs = [
    {
        'id': 'faq-1',
        'key': 'a1',
        'title': 'about.faq.asks.q1.title',
        'paragraph': 'about.faq.asks.q1.paragraph'
    },
    {
        'id': 'faq-2',
        'key': 'a2',
        'title': 'about.faq.asks.q2.title',
        'paragraph': 'about.faq.asks.q2.paragraph'
    },
    {
        'id': 'faq-3',
        'key': 'a3',
        'title': 'about.faq.asks.q3.title',
        'paragraph': 'about.faq.asks.q3.paragraph'
    },
    {
        'id': 'faq-4',
        'key': 'a4',
        'title': 'about.faq.asks.q4.title',
        'paragraph': 'about.faq.asks.q4.paragraph'
    },
    {
        'id': 'faq-5',
        'key': 'a5',
        'title': 'about.faq.asks.q5.title',
        'paragraph': 'about.faq.asks.q5.paragraph'
    },
    {
        'id': 'faq-6',
        'key': 'a6',
        'title': 'about.faq.asks.q6.title',
        'paragraph': 'about.faq.asks.q6.paragraph'
    },
    {
        'id': 'faq-7',
        'key': 'a7',
        'title': 'about.faq.asks.q7.title',
        'paragraph': 'about.faq.asks.q7.paragraph'
    },
    {
        'id': 'faq-8',
        'key': 'a8',
        'title': 'about.faq.asks.q8.title',
        'paragraph': 'about.faq.asks.q8.paragraph'
    },
    {
        'id': 'faq-9',
        'key': 'a9',
        'title': 'about.faq.asks.q9.title',
        'paragraph': 'about.faq.asks.q9.paragraph'
    },
    {
        'id': 'faq-10',
        'key': 'a10',
        'title': 'about.faq.asks.q10.title',
        'paragraph': 'about.faq.asks.q10.paragraph'
    },
    {
        'id': 'faq-11',
        'key': 'a11',
        'title': 'about.faq.asks.q11.title',
        'paragraph': 'about.faq.asks.q11.paragraph'
    }
]