import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';




i18next

    .use(Backend)
    .use(LanguageDetector)
    
    .use(initReactI18next) // bind react-i18next to the instance
    
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            wait: true
        }
    });



    


