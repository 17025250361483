import { useTranslation } from 'react-i18next';
export const WhoCanBuy = () => {
    const { t } = useTranslation();
    return (
        <section className="py-5 container">
            <div className="row">
                <div   className="col-lg-2">
                    <h3 className="text-uppercase p-ttl-sticky"><strong>{t('explore-el-tip-method.subtitle')}</strong></h3>
                </div>
                <div   className="col-lg-10">
                    <h2 className="ttl-section">{t('explore-el-tip-method.description.1.title')}</h2>
                    <p>{t('explore-el-tip-method.description.1.paragraph')}</p>
                </div>
                <div   className="mt-5 offset-lg-2 col-lg-10">
                    <h2 className="ttl-section">{t('explore-el-tip-method.description.2.title')}</h2>
                    <ul className="list-inline list-who-can-buy">
                        {/* <li>{t('explore-el-tip-method.description.2.list-description.0')}</li> */}
                        <li>{t('explore-el-tip-method.description.2.list-description.1')}</li>
                        <li>{t('explore-el-tip-method.description.2.list-description.2')}</li>
                        <li>{t('explore-el-tip-method.description.2.list-description.3')}</li>
                        <li>{t('explore-el-tip-method.description.2.list-description.4')}</li>
                        <li>{t('explore-el-tip-method.description.2.list-description.5')}</li>
                        <li>{t('explore-el-tip-method.description.2.list-description.6')}</li>
                        <li>{t('explore-el-tip-method.description.2.list-description.7')}</li>
                        <li>{t('explore-el-tip-method.description.2.list-description.8')}</li>
                    </ul>
                </div>
            </div>
        </section>

    )
}
