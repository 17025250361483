

export const ButtonWsp = () => {
  return (
    <a href="https://api.whatsapp.com/send?phone=+13125220912&text=%C2%A1Hola%21+Quiero+m%C3%A1s+informaci%C3%B3n" target="_blank" className="btn-whatsapp">
        <div className="btn-alert-wpp">
          <span>*</span>
          <img src="https://ucarecdn.com/acaf205f-7a23-4c09-9f95-7e80d72b9d48/WUPProperty1Default.png" alt="img-whatsapp"/>
          <p>Contact us</p>
        </div>
    </a >
  )
}
