
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Button';

/* Styles */
import '../styles/Hero.css';


const Page404 = () => {
  const { t } = useTranslation();
  return (
    <>
      <header>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="p-relative">
                  <h1 className="title-hero"><span>THINGS</span> IN PLACE</h1>
                  <h2>{t('subtitleHero')}</h2>
                </div>
                <button type="button" className="btn btn-video d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-play-fill" viewBox="0 0 16 16">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                  </svg>
                  {t('btn-video')}
                </button>
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 id="staticBackdropLabel">Video</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <iframe src="https://player.vimeo.com/video/723403681?h=c436db8ab8&color=C2988E&title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 form-hero text-end">
                <h1 className="w-100 text-end">Page not found</h1>
                <Button text="Home page"  url="https://tipmethod.net/" />
              </div>
            </div>
          </div>
        </div>

      </header>
    </>
  )
}

export default Page404