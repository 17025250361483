import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";

export const Button = ({url, text, target, modal, type = "primary"}) => {
  return (
    <AwesomeButton 
      type={type}
      href={url} 
      target={target} 
      >
      { text } 
      <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
      </svg>
    </AwesomeButton>
  )
}
