import "../styles/Video.css"; // import css
import { createRef, useState } from "react";
import { Playlist } from "reactjs-video-playlist-player";
import { useTranslation } from 'react-i18next';


export const VideoJS = () => {
  const { t } = useTranslation();
  
  const [videoList] = useState([
    {
      thumbnail: `${t('videos.video-1')}`,
      url: 'https://fusion-latina.netlify.app/videos/¿qué_es_things_in_place_ (360p).mp4',
      imgAlt: "Image 1 not found"
    },
    {
      thumbnail: `${t('videos.video-2')}`,
      url:
        "https://fusion-latina.netlify.app/videos/¿qué_es_ser_un_interior_planner_ (360p).mp4",
      imgAlt: "Image 2 not found"
    },
    {
      thumbnail: `${t('videos.video-3')}`,
      url: "https://fusion-latina.netlify.app/videos/¿cómo_me_certifico_como_interior_planner_ (360p).mp4",
      imgAlt: "Image 3 not found"
    },
    {
      thumbnail: `${t('videos.video-4')}`,
      url:
        "https://fusion-latina.netlify.app/videos/¿en_qué_consisten_los_módulos_ (360p).mp4",
      imgAlt: "Image 4 not found"
    },
    {
      thumbnail: `${t('videos.video-5')}`,
      url:
        "https://fusion-latina.netlify.app/videos/duración_de_certificación (360p).mp4",
      imgAlt: "Image 5 not found"
    },
    {
      thumbnail: `${t('videos.video-6')}`,
      url:
        "https://fusion-latina.netlify.app/videos/¿obtengo_un_certificado_cuando_finalizo_la_certificación_ (360p).mp4",
      imgAlt: "Image 6 not found"
    },
    {
      thumbnail: `${t('videos.video-7')}`,
      url:
        "https://fusion-latina.netlify.app/videos/¿cómo_me_ayuda_tip_luego_de_finalizar_la_certificación_ (360p).mp4",
      imgAlt: "Image 7 not found"
    },
    {
      thumbnail: `${t('videos.video-8')}`,
      url:
        "https://fusion-latina.netlify.app/videos/¿quién_puede_tomar_la_certificación_de_interior_planner_ (360p).mp4",
      imgAlt: "Image 8 not found"
    },
    {
      thumbnail: `${t('videos.video-9')}`,
      url:
        "https://fusion-latina.netlify.app/videos/aprende_a_poner_orden_en_tú_hogar (360p).mp4",
      imgAlt: "Image 9 not found"
    },
    {
      thumbnail: `${t('videos.video-10')}`,
      url:
        "https://fusion-latina.netlify.app/videos/¿quién_es_la_gurú_del_orden_marietta_vitale_ (360p).mp4",
      imgAlt: "Image 10 not found"
    },
    {
      thumbnail: `${t('videos.video-11')}`,
      url: "https://fusion-latina.netlify.app/videos/invitación_a_conocer_a_la_autora__marietta_vitale (360p).mp4",
      imgAlt: "Image 11 not found"
    }
  ]);

  const [currentVideo, setCurrentVideo] = useState(0);
  const vidRef = createRef(null);
  
  const params = {
    videos: videoList,
    autoPlay: false,
    showQueue: true,
    playForward: true,
    currentVideo,
    setCurrentVideo,
    vidRef
  };
  

  return (
    <>
      <div className="container pb-5">
        <div className="row">
          <div   className="col-lg-2">
            <h3 className="pt-3 p-ttl-sticky"><strong>{t('video.title')}</strong></h3>
          </div>
          <div className="col-lg-10">
            <Playlist playlistParams={params}  />
          </div>
        </div>
      </div>
    </>
  )
}
