import { HeroSections } from "../components/HeroSections"
import { useTitle } from "../hooks/useTitle"
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import { useRef, useState } from 'react';
import '../styles/Becas.css';



const Becas = () => {
    useTitle({ title: 'Becas' });
    const { t } = useTranslation();
    const form = useRef();

    const [show, setShow] = useState(true);

    const [hideForm, setHideForm] = useState(true);

    function changeState() {
        setShow(!show);
        setHideForm(!show);
    }

    const sendEmail = (e) => {
        e.preventDefault();

        const btn = document.querySelector('.btn');
        const work = document.querySelector('.success');

        emailjs.sendForm('service_ukck7u8', 'template_sg42yjl', form.current, 'vwSEFdSsXPM75Bn3x')
            .then((result) => {
                setShow(true);
                setTimeout(() => {
                    setHideForm(true);
                }, 100);
                console.log(result);
            }, (error) => {
                console.log(error);
                setShow(false);
                setTimeout(() => {
                    setHideForm(false);
                }, 100);
            });
    };
   
    
    return (
        <>
            <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.697560406911!2d-87.63351558433295!3d41.87785307340822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cbdac978335%3A0x1474ff99ff50748a!2s111%20W%20Jackson%20Blvd%20%231700a%2C%20Chicago%2C%20IL%2060604%2C%20EE.%20UU.!5e0!3m2!1ses!2sar!4v1656170691665!5m2!1ses!2sar"></iframe>
            <HeroSections
                sectionTitle={t('becas.separator')}
                title={t('becas.title')}
                paragraph={t('becas.paragraph')}
            />
            <div className="container form-footer input-becas">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-1')}</label>
                            <input required className="w-100" type="text" id="name" name="name" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-2')}</label>
                            <input required className="w-100" type="text" id="apellido" name="apellido" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-3')}</label>
                            <input required className="w-100" type="date" id="fechaNacimiento" name="fechaNacimiento" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-4')}</label>
                            <input required className="w-100" type="number" id="lineaTelefono" name="lineaTelefono" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-5')}</label>
                            <input required className="w-100" type="text" id="ciudad" name="ciudad" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-6')}</label>
                            <input required className="w-100" type="text" id="estado" name="estado" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-7')}</label>
                            <input required className="w-100" type="text" id="cp" name="cp" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-8')}</label>
                            <input required className="w-100" type="text" id="pais" name="pais" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-9')}</label>
                            <input required className="w-100" type="mail" id="email" name="email" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-10')}</label>
                            <input required className="w-100" type="number" id="celular" name="celular" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-18')}</label>
                            <input required className="w-100" type="text" id="advisor" name="advisor" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-11')}</label>
                            <input required className="w-100" type="text" id="industria" name="industria" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-12')}</label>
                            <input required className="w-100" type="text" id="aprobacionAnterior" name="aprobacionAnterior" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-13')}</label>
                            <input required className="w-100" type="text" id="asistenciaFinanciera" name="asistenciaFinanciera" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-14')}</label>
                            <input required className="w-100" type="text" id="situacionLaboral" name="situacionLaboral" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-15')}</label>
                            <input required className="w-100" type="text" id="empleador" name="empleador" />
                        </div>
                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-16')}</label>
                            <input required className="w-100" type="text" id="organizacion" name="organizacion" />
                        </div>

                        <div className="mt-2 col-lg-6">
                            <label>{t('becas.input-17')}</label>
                            <input required className="w-100" type="text" id="ingresoBruto" name="ingresoBruto" />
                        </div>
                    </div>

                    <div className="g-recaptcha" data-sitekey="6LcEBKUZAAAAAES-mvRyTD200oo8SD3F7ktJgyIG"></div>
                    {sendEmail.errors ? `${sendEmail.errors}` : null}
                    {show ? (
                        <>
                            <button onClick={changeState} id="btn" className="mt-3">
                                <p id="btnText">{t('form.send')}</p>
                                <div className="check-box">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                </div>
                            </button>
                        </>
                    ) : (
                        <>
                            <button onClick={changeState} id="btn" className={`active ${show ? "active mt-3" : ""}`}>
                                <p id="btnText">{t('form.tnks')}</p>
                                <div className="check-box">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                </div>
                            </button>
                        </>
                    )}
                    <p className="success"></p>
                </form>
            </div>
        </>
    )
}



















export default Becas