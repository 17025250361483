import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Translation } from './Translation';
import '../styles/NavBurger.css'

export const NavbarBurger = () => {

    const { t } = useTranslation();

    return (
        <nav className="navbar burguer">
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <img className="img-fluid py-3" src="https://ucarecdn.com/1643d458-27f2-4b0d-9910-d5aedf011f9d/LogoDesktopHover.png" alt="img-logo" />
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <hr></hr>
                </div>
                <div className="offcanvas-body">
                    <div className="d-flex flex-column align-start">
                        <Link
                            className="nav-link-burger"
                            to="/"
                        >
                            Home
                        </Link>
                        <Link
                            className="nav-link-burger"
                            to="/about-the-tip"
                        >
                            {t('menu.about')}
                        </Link>
                        
                        <a className="nav-link-burger" href="https://tipmethodcourses.net/producto/conviertete-en-miembro-de-tip/" target="_blank" rel="noreferrer">{t('menu.members')}</a>
                        <Link
                            className="nav-link-burger"
                            to="/books"
                        >
                            {t('menu.books')}
                        </Link>
                        <div className="dropdown">
                            <button className="dropdown-toggle dropdown-burguer" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                                {t('menu.education')}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                <li>
                                    <Link className="nav-link-burger"
                                        to="/education"
                                    >
                                        {t('menu.campus')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="nav-link-burger"
                                        to="/levels-of-ip"
                                    >
                                        {t('menu.levels')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <a className="nav-link-burger" href="https://tipmarketplace.net/" target="_blank" rel="noreferrer">{t('menu.find')}</a>
                        <Link
                            className="nav-link-burger"
                            to="/contact-us"
                        >
                            {t('menu.contact')}
                        </Link>

                        <Translation />
                        <hr></hr>
                        <ul className="list-inline">
                            <li><h4><strong>Things In Place, LLC</strong></h4></li>
                            <li className="mt-3">Things In Place, LLC</li>
                            <li>111 W. Jackson Blvd.</li>
                            <li>Suite 1700</li>
                            <li>Chicago, IL 60604</li>
                        </ul>
                        <ul className="list-inline">
                            <li><h4 className="text-uppercase"><strong>rrss</strong></h4></li>
                            <li className="img-socials mt-3 mb-5">
                                <a href="https://www.instagram.com/tip.method/" target="_blank" rel="noreferrer"><img src="https://ucarecdn.com/2c713767-a7ed-48ca-ae8c-b31b72260e5a/instagram.png" alt="img-ig" /></a>
                                <a href="https://www.facebook.com/TipMethod" target="_blank" rel="noreferrer"><img className="mx-2" src="https://ucarecdn.com/aeed920f-3850-434f-a7b5-442cfd293e1d/facebook.png" alt="img-fb" /></a>
                                <a href="https://api.whatsapp.com/send?phone=+13125220912&text=%C2%A1Hola%21+Quiero+m%C3%A1s+informaci%C3%B3n" target="_blank" rel="noreferrer"><img className="p-1" src="https://ucarecdn.com/ee85a0ae-eab5-44b3-919c-c8f3c6b3a6a8/wpp.png" alt="img-wpp" /></a>
                                <a href="mailto: admissions@tipmethod.net" target="_blank" rel="noreferrer"><img className="mx-2" src="https://ucarecdn.com/b5603f13-3e5c-4cac-b24e-9711870b4b5a/mail.png" alt="img-mail" /></a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </nav>
    )
}
