import { useTranslation } from 'react-i18next';
import { CardTestimonial } from '../components/CardTestimonial';
import { HeroSections } from "../components/HeroSections"
import '../styles/Membership.css'


const Membership = () => {

  const { t } = useTranslation();

  return (
    <>
      <HeroSections
        sectionTitle="membresia.quote"
        title="membresia.title"
      />
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-3 col-lg-3">
              <ul className="list-inline">
                <li>Certificación</li>
                <li>Ordena tu hogar</li>
                <li>Seminarios</li>
                <li>Mentoría TIP</li>
                <li>Libros</li>
                <li>Kit de trabajo</li>
                <li>Membresía</li>
              </ul>
            </div>
            <div className="col-9 col-lg-9">
              <div className="row">
                <CardTestimonial
                  classCols="card-prod col-6 col-lg-4"
                  image="https://ucarecdn.com/ee1cce67-f23a-4e54-83dc-4c540080e5c7/Rectangle5.png"
                  title="Conviértete en miembro de TIP"
                  subtitle="Trained interior planner"
                  urlBtn="#"
                />
                <CardTestimonial
                  classCols="card-prod col-6 col-lg-4"
                  image="https://ucarecdn.com/99bbe403-2e53-4bac-bc85-76e7871e07b3/Rectangle51.png"
                  title="Mentoría TIP"
                  subtitle="Trained interior planner"
                  urlBtn="#"
                />
                <CardTestimonial
                  classCols="card-prod col-6 col-lg-4"
                  image="https://ucarecdn.com/97354c57-991f-4353-afcb-fd39a5b0ff46/Rectangle29.png"
                  title="Kit de Trabajo"
                  subtitle="Trained interior planner"
                  urlBtn="#"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="quote-marie bg-separator py-5">
        <div className="container">
          <div className="row">
            <article   className="col-lg-6">
              <div className="accordion" id="disclaimer">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="dsc">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#c1" aria-expanded="true" aria-controls="c1">
                      DISCLAIMER
                    </button>
                  </h2>
                  <div id="c1" className="accordion-collapse collapse show" aria-labelledby="dsc" data-bs-parent="#disclaimer">
                    <div className="accordion-body">
                      <p>
                        Toda la información y el contenido en este sitio web y cursos son publicados para información general y educación. Things in Place Method, LLC no hace ninguna declaración, representación o garantía en cuanto a la exactitud, confiabilidad de la información y el contenido en este sitio web. Things in Place Method, LLC, sus afiliados y staff no aceptan ninguna responsabilidad por cualquier pérdida o daño directo o indirecto sufrido u ocasionado por cualquier persona que participe en este programa.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article   className="col-lg-6">
              <div className="accordion" id="copy">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="cp">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#c2" aria-expanded="true" aria-controls="c2">
                      COPYRIGHT
                    </button>
                  </h2>
                  <div id="c2" className="accordion-collapse collapse show" aria-labelledby="cp" data-bs-parent="#disclaimer">
                    <div className="accordion-body">
                      <p>
                        Los contenidos de este sitio web, cursos, vídeos y/o materiales están protegidos. Se prohíbe la reproducción y/o distribución de cualquier tipo, excepto con el permiso previo y por escrito de Things in Place Method, LLC. Ninguna persona puede descargar, duplicar, reproducir, editar, publicar o difundir la información presente a través de cualquier medio en su totalidad o en parte.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  )
}

export default Membership