import { useTranslation } from 'react-i18next';
/* Styles */
import '../styles/Description.css';
import { Button } from './Button';


export const Descriptions = ({ quoteDivider, titleDescription, paragraphDescription, textBtn, url }) => {

    const { t } = useTranslation();

    return (
        <section className="description py-5">
            <article>
                <div className="container">
                    <div className="row">
                        <div   className="col-lg-2">
                            <h3 className="p-ttl-sticky"><strong>{ t(`${ quoteDivider }`) }</strong></h3>
                        </div>
                        <div   className="col-lg-10">
                            <h2>{ t(`${ titleDescription }`) }</h2>
                            <p className="text-justify mb-3 mt-5">{ t(`${ paragraphDescription }`) }</p>
                            { url ? <Button className={ url ? 'd-block' : 'd-none' } url={ url } text={ t(`${ textBtn }`) }/> : null }
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}
