
import { useTranslation } from 'react-i18next';

const lngs = {
    en: { nativeName: 'ENG' },
    es: { nativeName: 'ESP' }
};



export const Translation = () => {
    const { t, i18n } = useTranslation();


    return (
        <div className="d-flex">
            {Object.keys(lngs).map(lng => (
                <button className="btn-translate mx-2" type="submit" key={lng} onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>{lngs[lng].nativeName}</button>
            ))
            }
        </div>
    )
}
