export const services = [
    {
        'id': 'serv-1',
        'img': 'https://ucarecdn.com/67d3ff32-0c03-4091-91a5-f92b92544b2e/-/preview/-/quality/smart/',
        'subtitle': 'Trained Interior Planner',
        'title': "services.title-1",
        'url': 'https://tipmethodcourses.net/categoria-producto/cursos/',
    },{
        'id': 'serv-2',
        'img': 'https://ucarecdn.com/3a1e446f-b463-4d46-8168-cb3805cc6e9b/-/preview/-/quality/smart/',
        'subtitle': 'Trained Interior Planner',
        'title': "services.title-2",
        'url': 'https://tipmethodcourses.net/producto/modulos-del-2-al-10/',
    },{
        'id': 'serv-3',
        'img': 'https://ucarecdn.com/d14cfa9e-3da8-44f4-96c6-fdf40da91621/-/preview/-/quality/smart/',
        'subtitle': 'Trained Interior Planner',
        'title': "services.title-3",
        'url': 'https://tipmethodcourses.net/categoria-producto/seminarios/',
    },{
        'id': 'serv-4',
        'img': 'https://ucarecdn.com/5aba6b44-fafb-4eb9-90ef-bcb69b4aa2aa/-/preview/-/quality/smart/',
        'subtitle': 'Trained Interior Planner',
        'title': "services.title-4",
        'url': 'https://tipmethodcourses.net/producto/mentoria-tip/',
    },{
        'id': 'serv-5',
        'img': 'https://ucarecdn.com/27292fc6-1b53-427f-9432-5ebcdf1f191e/-/preview/-/quality/smart/',
        'subtitle': 'Trained Interior Planner',
        'title': "services.title-5",
        'url': '/books',
    },{
        'id': 'serv-6',
        'img': 'https://ucarecdn.com/a125fbaf-8afc-4b55-957c-7bd8fd60dbe9/-/preview/-/quality/smart/',
        'subtitle': 'Trained Interior Planner',
        'title': "services.title-6",
        'url': 'https://tipmethodcourses.net/producto/kit-de-trabajo/',
    },{
        'id': 'serv-7',
        'img': 'https://ucarecdn.com/41832a48-f2e9-4da2-be95-7ff672e6f85e/-/preview/-/quality/smart/',
        'subtitle': 'Trained Interior Planner',
        'title': "services.title-7",
        'url': 'https://tipmethodcourses.net/producto/conviertete-en-miembro-de-tip/',
    }
]