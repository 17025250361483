export const testimonials = [
    {
        'id': 'test-1',
        'img': 'https://ucarecdn.com/baae23e6-ab8f-42fe-a336-aa9e814b826d/monica_ramos.jpg',
        'subtitle': 'historias.testimonios.first.paragraph',
        'title': "historias.testimonios.first.name",
        'location': 'historias.testimonios.first.location',
    },
    {
        'id': 'test-2',
        'img': 'https://ucarecdn.com/9ebdc31e-74fc-4066-896a-ed9eac91a312/morelva.jpeg',
        'subtitle': 'historias.testimonios.second.paragraph',
        'title': "historias.testimonios.second.name",
        'location': 'historias.testimonios.second.location',
    },
    {
        'id': 'test-3',
        'img': 'https://ucarecdn.com/ecc7e1c5-6998-4fd7-80ba-7c1009629f86/zailet_rea.jpg',
        'subtitle': 'historias.testimonios.third.paragraph',
        'title': "historias.testimonios.third.name",
        'location': 'historias.testimonios.third.location',
    },
    {
        'id': 'test-4',
        'img': 'https://ucarecdn.com/af26197e-dd7d-48b6-ba74-341b35481b39/maria_angelica.jpg',
        'subtitle': 'historias.testimonios.fourth.paragraph',
        'title': "historias.testimonios.fourth.name",
        'location': 'historias.testimonios.fourth.location',
    },
    {
        'id': 'test-5',
        'img': 'https://ucarecdn.com/4dbeaf3b-5870-4f69-b746-dc04c566232b/maria_paez.jpg',
        'subtitle': 'historias.testimonios.fiveth.paragraph',
        'title': "historias.testimonios.fiveth.name",
        'location': 'historias.testimonios.fiveth.location',
    },
    {
        'id': 'test-6',
        'img': 'https://ucarecdn.com/4663ccf7-3cf2-4422-853d-2dacffc6dc05/tatiana_espinoza.jpeg',
        'subtitle': 'historias.testimonios.sixth.paragraph',
        'title': "historias.testimonios.sixth.name",
        'location': 'historias.testimonios.sixth.location',
    },
    {
        'id': 'test-7',
        'img': 'https://ucarecdn.com/ad2ec7e6-d7b8-4102-b477-ecf9d0afaefe/andrea_costa_lopez.jpg',
        'subtitle': 'historias.testimonios.seven.paragraph',
        'title': "historias.testimonios.seven.name",
        'location': 'historias.testimonios.seven.location',
    },
    {
        'id': 'test-8',
        'img': 'https://ucarecdn.com/28128b1c-ae93-4595-b387-cc59116d2d7c/marcela_lalilde.jpeg',
        'subtitle': 'historias.testimonios.eight.paragraph',
        'title': "historias.testimonios.eight.name",
        'location': 'historias.testimonios.eight.location',
    },
    {
        'id': 'test-9',
        'img': 'https://ucarecdn.com/ebf5812f-155b-4d7b-8f27-32ca44ad244c/lizzette_morales.jpg',
        'subtitle': 'historias.testimonios.nine.paragraph',
        'title': "historias.testimonios.nine.name",
        'location': 'historias.testimonios.nine.location',
    },
    {
        'id': 'test-10',
        'img': 'https://ucarecdn.com/7340db69-66a5-46ad-b8f0-350a8d154ac9/karina_gutierrez.jpeg',
        'subtitle': 'historias.testimonios.ten.paragraph',
        'title': "historias.testimonios.ten.name",
        'location': 'historias.testimonios.ten.location',
    },
    {
        'id': 'test-11',
        'img': 'https://ucarecdn.com/371e61c7-8433-499b-b86e-813e30878f33/debora_goldenberg.jpg',
        'subtitle': 'historias.testimonios.eleven.paragraph',
        'title': "historias.testimonios.eleven.name",
        'location': 'historias.testimonios.eleven.location',
    },
    {
        'id': 'test-12',
        'img': 'https://ucarecdn.com/59cc647a-4ff3-429d-ab2d-5b189049ca87/laura_morales.jpg',
        'subtitle': 'historias.testimonios.twelve.paragraph',
        'title': "historias.testimonios.twelve.name",
        'location': 'historias.testimonios.twelve.location',
    },
    {
        'id': 'test-13',
        'img': 'https://ucarecdn.com/1cd06904-0333-46ca-8776-472293905b17/Itzela_Mollah.jpeg',
        'subtitle': 'historias.testimonios.thirdteen.paragraph',
        'title': "historias.testimonios.thirdteen.name",
        'location': 'historias.testimonios.thirdteen.location',
    }
]