import { useTranslation } from 'react-i18next';

import { Carousel } from "../components/Carousel"
import { Descriptions } from "../components/Descriptions"
import { Hero } from "../components/Hero"

import { Button } from "../components/Button";
import { Testimonial } from "../components/Testimonial";
import { Gallery } from '../components/Gallery';
import { WhoCanBuy } from '../components/WhoCanBuy';
import { useTitle } from '../hooks/useTitle';
import { VideoJS } from '../components/VideoJS';
import { ContactFooter } from '../components/ContactFooter';




const Home = () => {
  const { t } = useTranslation();
  useTitle({ title: 'Home' });
  return (
    <>
      <Hero />
      <Descriptions 
        quoteDivider="aboutBtnText"
        titleDescription="aboutTitle"
        paragraphDescription="aboutSubtitle"
        textBtn="aboutBtnText"
        url="/about-the-tip"
      />
      <Carousel />
      <WhoCanBuy />
      <VideoJS />
      <section className="py-5 bg-separator">
        <div className="container">
          <div className="row">
            <article   className="col-lg-5 vertical-center">
              <ul className="list-inline">
                <li><h4>{t('banner.title.quote-1')}</h4></li>
                <li><h4>{t('banner.title.quote-2')}</h4></li>
                <li><h4>{t('banner.title.quote-3')}</h4></li>
                <li className="py-5"><Button url="https://tipmethodcourses.net/producto/conviertete-en-miembro-de-tip/" target="_blank"  text={t('banner.title.btn-quote')} /></li>
              </ul>
            </article>
            <article className="col-lg-7 grid-images">
              <div className="row">
                <div   className="col-6 col-lg-6">
                  <img className="img-fluid" src="https://ucarecdn.com/b382b9e8-81fd-4b2a-b879-a40bcf4f2e16/HowtoKeepThingsTidyinYourHome.webp" alt={t('banner.title.quote-1')}></img>
                  <img className="img-fluid" src="https://ucarecdn.com/bdaf9a5f-b87f-42fe-b371-cf03bb2feb98/mujerordenando_3cb6f8ce_1335x2000.webp" alt={t('banner.title.quote-1')}></img>
                </div>
                <div   className="col-6 col-lg-6">
                  <img className="img-fluid" src="https://ucarecdn.com/9ed885d1-9ad6-4838-998d-74839489117b/mujerordenandoydecorandoestanteria00503869o_416db0ff_1333x2000.webp" alt={t('banner.title.quote-1')}></img>
                  <img className="img-fluid" src="https://ucarecdn.com/1b6b0499-3004-4d92-8aca-2ef85384cf01/plan_it_4.png" alt={t('banner.title.quote-1')}></img>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
      <Testimonial />
      <section className="certificated bg-separator">
        <div className="bg-certificated">
          <div className="container">
            <div className="row">
              <article   className="py-5 col-lg-6">
                <ul className="list-inline">
                  {/* <li><h5>{t('banner-second.title.subtitle')}</h5></li> */}
                  <li><h3>{t('banner-second.title.title')}</h3></li>
                  {/* <li className="py-5"><Button url="https://tipmethodcourses.net/producto/conviertete-en-miembro-de-tip/" target="_blank" text={t('banner-second.title.btn-quote')} /></li> */}
                </ul>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className="redirections py-5">
        <div className="container">
          <div className="row border-b py-3">
            <article   className="col-lg-2">
              <h6 className="separator-section text-uppercase"><strong>{t('redirections.planners.title-section')}</strong></h6>
            </article>
            <article   className="col-lg-8 py-4">
              <h2 className="ttl-section text-uppercase">{t('redirections.planners.title')}</h2>
            </article>
            <article   className="col-lg-2 my-auto">
              <Button url="https://tipmarketplace.net/" target="_blank" text={t('redirections.educación.btn-quote')} />
            </article>
          </div>
          <div className="row border-b pt-5 pb-3">
            <article   className="col-lg-2">
              <h6 className="separator-section text-uppercase"><strong>{t('redirections.educación.title-section')}</strong></h6>
            </article>
            <article   className="col-lg-8 py-4">
              <h2 className="ttl-section text-uppercase">{t('redirections.educación.title')}</h2>
            </article>
            <article   className="col-lg-2 my-auto">
              <Button url="/education" text={t('redirections.educación.btn-quote')} />
            </article>
          </div>
        </div>
      </section>
      <Gallery 
        img1="https://ucarecdn.com/34aec822-2a1e-4348-902c-0b4fda6880e2/final_1.png"
        img2="https://ucarecdn.com/c89dc640-a759-4dbe-85fe-cddd024769a3/final_2.png"
        img3="https://ucarecdn.com/d1bc4ac8-4534-467d-a63e-c876dafef0a1/final_3.png"
      />
      <ContactFooter />
    </>
  )
}

export default Home