import { Button } from "../components/Button"
import { Gallery } from "../components/Gallery"
import { HeroSections } from "../components/HeroSections"
import { useTitle } from '../hooks/useTitle';
import { useTranslation } from 'react-i18next';


export const Books = () => {
    useTitle({ title: 'Books' });
    const { t } = useTranslation();
    return (
        <>  
            <Gallery
                img1="https://ucarecdn.com/332a8a4a-b25f-4d5b-b5e3-eb8f56619de7/450x5262.png"
                img2="https://ucarecdn.com/32d26a4d-34ed-4722-a18e-c68c6433d1fd/608x1080.webp"
                img3="https://ucarecdn.com/9ffc20fd-c10c-4fe6-8230-82be161fa4f6/libro2.webp"
            />
            <HeroSections
                sectionTitle="books.separator"
                title="books.title"
                paragraph="books.paragraph"
                job="about.category-sign"
                name="about.category-name"
            />
            <div className="container">
                <div className="row">
                    <div className="col-12 offset-lg-2 col-lg-4 my-1">
                        <Button text={t('books.btn-tutora')} url="https://www.mariettavitale.com/" target="_blank" />
                    </div>
                    <div className="col-12 col-lg-3 my-1">
                        <Button text={t('books.btn-books')} url="https://tipmethodcourses.net/categoria-producto/libros/" target="_blank" />
                    </div>
                </div>
            </div>
            
        </>
    )
}
