import { useTranslation } from 'react-i18next';
import { getModules } from '../helpers/getModules';

export const AccordionItem = () => {

    const { t } = useTranslation();
    const AllModules = getModules();

    return (
        <div className="accordion" id="accordion">
            {
                AllModules.map(( { id , key, title, paragraph} ) => (
                    <div className="accordion-item">
                        <h2 className="accordion-header" id={id}>
                            <button className="accordion-button collapsed" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target={`#${key}`} 
                                aria-expanded="false" 
                                aria-controls={key}>
                                { t(title) }
                            </button>
                        </h2>
                        <div id={key} className="accordion-collapse collapse" aria-labelledby={id} data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <p>{ t(paragraph) }</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
