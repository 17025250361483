import React from 'react';
import ReactDOM from 'react-dom/client';
/* Router */
import { BrowserRouter } from "react-router-dom";
import App from './App';
/* Translate  */
import './i18n';
/* Bootstrap */
import 'bootstrap/dist/css/bootstrap.min.css';
/* Styles */
import './index.css';
/* Styles */
import './styles/Normalize.css';

import ScrollToTop from './helpers/ScrollToTop';

/* ScrollToTop */


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.Suspense>
      <ScrollToTop />
      <App />
    </React.Suspense>
  </BrowserRouter>
);
