import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { useForm } from '../hooks/useForm';


export const ContactFooter = () => {

    const { t } = useTranslation();

    const { formState, onInputChange, onResetForm, name, email, country, phone, message } = useForm({
        name: '',
        email: '',
        country: '',
        phone: '',
        message: '',
    });

    const [show, setShow] = useState(true);

    const [hideForm, setHideForm] = useState(true);

    function changeState() {
        setShow(!show);
        setHideForm(!show);
    }
    
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ukck7u8', 'template_brtkkrp', formState.current, 'vwSEFdSsXPM75Bn3x')
            .then((result) => {
                setShow(true);
                setTimeout(() => {
                    setHideForm(false);
                    onResetForm();
                }, 100);
            }, (error) => {
                setShow(false);                
                setTimeout(() => {
                    setHideForm(false);
                    onResetForm();
                }, 100);
                
            });

    };


    

    return (
        <div className="py-5 container form-footer"  >
            <h2 className="mb-2 text-uppercase">{t('menu.contact')}</h2>
            <div className="d-flex justify-content-between mb-3 phones">
                <a className="img-wpp" href="https://api.whatsapp.com/send?phone=+13125220912&text=%C2%A1Hola%21+Quiero+m%C3%A1s+informaci%C3%B3n" target="_blank"><img className="p-1 icon-wpp" src="https://ucarecdn.com/ee85a0ae-eab5-44b3-919c-c8f3c6b3a6a8/wpp.png" alt="img-wpp" />+1 (312) 522-0912</a>
                <a href="tel: +1 (312) 376-4048"><img className="p-1 icon-phone" src="https://ucarecdn.com/fe0bdc03-23b4-45b0-82a0-657f435375df/phonesymbol2.png" alt="img-wpp" />+1 (312) 376-4048</a>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.697560406911!2d-87.63351558433295!3d41.87785307340822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cbdac978335%3A0x1474ff99ff50748a!2s111%20W%20Jackson%20Blvd%20%231700a%2C%20Chicago%2C%20IL%2060604%2C%20EE.%20UU.!5e0!3m2!1ses!2sar!4v1656170691665!5m2!1ses!2sar"></iframe>
            {
                hideForm
                    ?
                    (
                        <>
                            <form className="mt-5" ref={formState} onSubmit={sendEmail}>
                                <div className="row">
                                    <div className="input-flex col-12 col-lg-6">
                                        <label className="d-flex flex-column justify-content-center">{t('form.name')}</label>
                                        <input className="w-100" type="text" placeholder={t('form.name')} required id="name" name="name" onChange={onInputChange} value={name} />

                                    </div>
                                    <div className="input-flex col-12 col-lg-6">
                                        <label className="d-flex flex-column justify-content-center">Email</label>
                                        <input className="w-100" type="email" placeholder="Email" required id="email" name="email" onChange={onInputChange} value={email} />

                                    </div>
                                    <div className="input-flex col-12 col-lg-6">
                                        <label className="d-flex flex-column justify-content-center">{t('form.country')}</label>
                                        <input className="w-100" type="text" placeholder={t('form.country')} required id="country" name="country" onChange={onInputChange} value={country} />

                                    </div>
                                    <div className="input-flex col-12 col-lg-6">
                                        <label className="d-flex flex-column justify-content-center">{t('form.phone')}</label>
                                        <input className="w-100" type="number" placeholder={t('form.phone')} required id="phone" name="phone" onChange={onInputChange} value={phone} />

                                    </div>
                                    <div className="input-flex col-12 col-lg-12">
                                        <label>{t('form.message')}</label>
                                        <textarea placeholder={t('form.message')} name="message" onChange={onInputChange} value={message} />
                                    </div>
                                    <div className="g-recaptcha" data-sitekey="6LcEBKUZAAAAAES-mvRyTD200oo8SD3F7ktJgyIG"></div>
                                    {sendEmail.errors ? `${sendEmail.errors}` : null}
                                    {
                                        show
                                            ?
                                            (
                                                <button className="mt-3" onClick={sendEmail} id="btn" >
                                                    <p id="btnText">{t('form.send')}</p>
                                                    <div className="check-box">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                            <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </div>
                                                </button>
                                            )
                                            :
                                            (
                                                <button onClick={changeState} id="btn" className={`active ${show ? "active mt-3" : ""}`}>
                                                    <p id="btnText">{t('form.tnks')}</p>
                                                    <div className="check-box">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                            <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </div>
                                                </button>
                                            )
                                    }
                                    <p className="success"></p>
                                </div>
                            </form>
                        </>
                    )
                    :
                    (<h2 className="thnks-msg">{t('form.tnks')}</h2>)
            }
        </div>
    );
};