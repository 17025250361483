import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";
import { CardTestimonial } from './CardTestimonial';
//Styles
import '../styles/CardTestimonial.css';
import { getTestimonials } from '../helpers/getTestimonials';

export const Testimonial = () => {

    const { t } = useTranslation();
    const allTestimonials = getTestimonials();

    return (
        <section className="testimonial py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                        <h3   className="text-uppercase p-ttl-sticky pt-3"><strong>{t('historias.title')}</strong></h3>
                    </div>
                    <div   className="col-lg-10">
                        <h2 className="ttl-section text-uppercase"  >{t('historias.subtitle')}</h2>
                        <Swiper
                            breakpoints={{
                                // when window width is >= 640px
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },

                            }}
                            spaceBetween={50}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            navigation={true}
                            modules={[Navigation, Autoplay]}
                        >
                            {
                                allTestimonials.map(({ id, img, subtitle, title, location }) => (
                                    <SwiperSlide>
                                        <CardTestimonial
                                            key={id}
                                            image={img}
                                            subtitle={t(subtitle)}
                                            title={t(title)}
                                            location={t(location)}
                                        />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}
