import BeforeAfterSlider from 'react-before-after-slider'


import { HeroSections } from '../components/HeroSections';
import { Descriptions } from '../components/Descriptions';
import { useTranslation } from 'react-i18next';
import { Gallery } from '../components/Gallery';
import { useTitle } from '../hooks/useTitle';
import { Faqs } from '../components/Faqs';
import { ContactFooter } from '../components/ContactFooter';
import { Button } from '../components/Button';



const AboutTheTip = () => {

  const { t } = useTranslation();
  useTitle({ title: 'About the TIP' });
 
  return (
    <>
      <HeroSections
        sectionTitle="about.quote"
        title="about.title"
        paragraph="about.paragraph"
        job="about.category-sign"
        name="about.category-name"
      />
      <Gallery 
        img1="https://ucarecdn.com/6646482f-484f-4820-ada1-678ca90226ad/Rectangle19.svg"
        img2="https://ucarecdn.com/bb3fd841-9b1c-42ae-8a0b-f489990e0b3d/Rectangle18.svg"
        img3="https://ucarecdn.com/92ca0764-e33f-4f15-aaf1-7c25bffa45d2/final_3.png"
      />
      
      {/* <WhoCanBuy /> */}
      <section className="quote-marie bg-separator">
        <div className="bg-mision">
          <div className="container">
            <div className="row">
              <article   className="py-5 col-lg-6">
                <h2>{t('about.mission.title')}</h2>
                <p className="mt-3">{t('about.mission.paragraph')}</p>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          
          <div className="row">
            <div className="col-lg-2">
              <h2 className="separator-section p-ttl-sticky">{t('about.faq.quote-divider-3')}</h2>
            </div>
            <div className="col-lg-10">
              <div className="accordion" id="accordion">
                <Faqs />
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Descriptions
        className="my-5"
        quoteDivider="about.description-2.quote-divider-2"
        titleDescription="about.description-2.quote-title-2"
        paragraphDescription="about.description-2.quote-paragraph-2"
      />
      <section className="py-5">
        <div className="container text-center">
          <h2>{t('about.description-3.title')}</h2>
          <BeforeAfterSlider
            before="https://ucarecdn.com/ae67c5f9-41fa-4d33-affd-06cdee6df56a/"
            after="https://ucarecdn.com/f4a1357b-457b-406f-be06-b1174e19f096/"
            width={640}
            height={480}
            className="my-5 mx-auto"
          />
          <BeforeAfterSlider
            before="https://ucarecdn.com/653377a5-364f-457b-91f2-244822447196/despues.jpg"
            after="https://ucarecdn.com/af15fa57-356e-4d15-a3ea-0c14af61ee99/antes.jpg"
            width={640}
            height={480}
            className="mt-5 mx-auto"
          />
          <BeforeAfterSlider
            before="https://ucarecdn.com/bab1e3e2-f129-4cb2-a831-d96e4e7fd367/"
            after="https://ucarecdn.com/f8fdbf4a-5779-47a8-90dd-964cfdeced51/"
            width={640}
            height={480}
            className="my-5 mx-auto"
          />
        </div>
      </section>
      <section className="py-5 bg-separator talks">
        <div className="container">
          <div className="row">
            <article   className="py-5 col-lg-6">
              <h5>{t('about.events.quote-divider-3')}</h5>
              <h2 className="mb-3">{t('about.events.quote-title-3')}</h2>
              <Button text={t('about.events.btn-events')} url="https://www.mariettavitale.com/" target="_blank" />
            </article>
            <article   className="py-5 col-lg-6">
              <p className="text-justify">{t('about.events.quote-paragraph')}</p>
            </article>
            
          </div>
        </div>
      </section>
      <ContactFooter />
    </>
  )
}

export default AboutTheTip