import { planners } from "../data/planners";

export const getPlannersByCountry = ( country = '' ) => {

    country.toLocaleLowerCase().trim();

    if( country.length === 0 ) return [];

    return planners.filter( planner => planner.country.toLocaleLowerCase().includes( country ) );

}