import { Descriptions } from "../components/Descriptions"
import { HeroSections } from "../components/HeroSections"
import { Gallery } from "../components/Gallery";

import { useTranslation } from 'react-i18next';
import { useTitle } from "../hooks/useTitle";
import { ContactFooter } from "../components/ContactFooter";


const LevelsOfIp = () => {

  const { t } = useTranslation();
  useTitle({ title: 'Levels of IP' });

  return (
    <>
      <HeroSections
        sectionTitle="nivelesIp.quote"
        title="nivelesIp.title"
      />
      <div className="levels container mb-5">
        <div className="row">
          <div className="col-4"  >
            <img src="https://ucarecdn.com/dd9d0e75-2154-4c63-9b88-b884e7c275df/Property1Default_.png" className="img-fluid p-3" alt="level 1"></img>
          </div>
          <div className="col-4"  >
            <img src="https://ucarecdn.com/d858f560-073a-41b3-a95b-9cd6938fa376/Property1Default.png" className="img-fluid p-3" alt="level 2"></img>
          </div>
          <div className="col-4"  >
            <img src="https://ucarecdn.com/ebbb4412-02fb-4523-8bff-5d964498c05d/Property1Default.png" className="img-fluid p-3" alt="level 3"></img>
          </div>
        </div>
      </div>
      <Descriptions
        quoteDivider="nivelesIp.description-1.quote-divider-1"
        titleDescription="nivelesIp.description-1.quote-title-1"
        paragraphDescription="nivelesIp.description-1.quote-paragraph-1"
      />
      <Gallery 
        img1="https://ucarecdn.com/a7b47448-e7a1-4b76-be9f-0976a7b5429f/NivelesdeIP01.png"
        img2="https://ucarecdn.com/08223ba7-21bd-4745-a6a7-4c10117c7e6a/NivelesdeIp02.png"
        img3="https://ucarecdn.com/f0492b69-1e13-41d2-874c-5a4bcf8b3c58/final_3.png"
      />
      <HeroSections
        sectionTitle="nivelesIp.description-1.quote-divider-2"
        title="nivelesIp.description-1.quote-title-2"
      />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <h3   className="separator-section p-ttl-sticky">{t('nivelesIp.description-1.quote-divider-1')}</h3>
            </div>
            <div   className="col-lg-10">
              <table className="table ctd">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th className="text-uppercase" scope="col">certified</th>
                    <th className="text-uppercase" scope="col">pro</th>
                    <th className="text-uppercase" scope="col">master</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-1')}</th>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-2')}</th>
                    <td>50</td>
                    <td>100</td>
                    <td>150</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-3')}</th>
                    <td>250</td>
                    <td>250</td>
                    <td>250</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-4')}</th>
                    <td>150</td>
                    <td>300</td>
                    <td>450</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-5')}</th>
                    <td>50</td>
                    <td>100</td>
                    <td>150</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-6')}</th>
                    <td>600</td>
                    <td>850</td>
                    <td>1100</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <hr className="my-5"></hr>
      </div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <h3   className="separator-section p-ttl-sticky">{t('nivelesIp.second-table.title-table')}</h3>
            </div>
            <div className="col-lg-10">
              <table   className="table pnt">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{t('nivelesIp.second-table.table-1')}</th>
                    <th scope="col">{t('nivelesIp.second-table.table-2')}</th>
                    <th scope="col">{t('nivelesIp.second-table.table-3')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-1')}</th>
                    <td>100</td>
                    <td>1</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-2')}</th>
                    <td>25</td>
                    <td>12</td>
                    <td>300</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-3')}</th>
                    <td>250</td>
                    <td>1</td>
                    <td>250</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-4')}</th>
                    <td>150</td>
                    <td>6</td>
                    <td>950</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.first-table.data-5')}</th>
                    <td>50</td>
                    <td>24</td>
                    <td>1200</td>
                  </tr>
                  <tr>
                    <th scope="row">{t('nivelesIp.second-table.data-4')}</th>
                    <td></td>
                    <td></td>
                    <td>2750</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section className="quote-levels bg-separator my-5">
        <div className="bg-levels">
          <div className="py-5 container">
            <div className="row"  >
              <article className="col-lg-3">
                <img src="https://ucarecdn.com/ca2fda4c-1c21-4624-b15e-503cfbbe718d/isc.png" className="img-fluid" alt="misc"></img>
              </article>
              <article   className="col-lg-3">
                <p>{t('nivelesIp.quote-divider.quote')}</p>
              </article>
            </div>
          </div>
        </div>
      </section>
      <ContactFooter />
    </>
  )
}

export default LevelsOfIp