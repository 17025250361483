import { Routes, Route } from 'react-router-dom';
/* Components */
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

/* Pages */
import Home from '../pages/Home';
import AboutTheTip from '../pages/AboutTheTip';
import ContactUs from '../pages/ContactUs';
import Education from '../pages/Education';
import FindTipPlanner from '../pages/FindTipPlanner';
import LevelsOfIp from '../pages/LevelsOfIp';
import Membership from '../pages/Membership';
import PlannerPage from '../pages/PlannerPage';
import Becas from '../pages/Becas';
import { Books } from '../pages/Books';
import Page404 from '../pages/Page404';






export const AppRouter = () => {
    return (
        <>
            <Navbar />
            <Routes>
                
                <Route path="/" element={<Home />} />
                <Route path="about-the-tip" element={<AboutTheTip />} />
                <Route path="education" element={<Education />} />
                <Route path="find-tip-planner" element={<FindTipPlanner />} />
                <Route path="find-tip-planner/:id" element={<PlannerPage />} />
                <Route path="levels-of-ip" element={<LevelsOfIp />} />
                <Route path="membership" element={<Membership />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="becas" element={<Becas />} />
                <Route path="books" element={<Books />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
            <Footer />
        </>
    )
}
