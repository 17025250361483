import { useTranslation } from 'react-i18next';
// import Swiper core and required modules
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay } from "swiper";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";
import '../styles/Carousel.css'
import { CardTestimonial } from './CardTestimonial';
import { getServices } from '../helpers/getServices';
const AllServices = getServices();


export const Carousel = () => {

  const { t } = useTranslation();


  return (
    <section className="carousel">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <h3   className="p-ttl-sticky pt-5"><strong>{t('explore-el-tip-method.title')}</strong></h3>
          </div>
          <div   className="col-lg-10 p-relative">
            <Swiper
              breakpoints={{
                // when window width is <= 320px     
                320: {
                  slidesPerView: 1,
                },
                // when window width is <= 480px     
                480: {
                  slidesPerView: 1,
                },

                // when window width is <= 640px     
                640: {
                  slidesPerView: 2,
                }
              }}
              spaceBetween={50}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Navigation, Autoplay]}
            >
              {
                AllServices.map(({ id, img, title, subtitle, url }) => (
                  <SwiperSlide>
                    <CardTestimonial
                       
                      classCols="card-home"
                      key={id}
                      image={img}
                      title={t(title)}
                      subtitle={subtitle}
                      urlBtn={url}

                      />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </div>
      </div>
    </section >
  )


}
