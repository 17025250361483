import { Navigate, useParams } from "react-router-dom"
import { getPlannerById } from "../helpers/getPlannerById";

const PlannerPage = () => {

    const { id } = useParams();

    const planner = getPlannerById( id );
    
    const { name, location, img, certification, mail, phone, description } = planner;

    const onNavigateBack = () => {
        return <Navigate to={ -1 } />
    }

    //* TODO: Cambiar a un 404
    if( !planner ){
        return <Navigate to="find-tip-planner" />;
    }


    return (
        <article>
            <button> Atras </button>
            <ul>
                <li>{name}</li>
                <li>{location}</li>
                <li>{ img }</li>
                <li>{certification}</li>
                <li>{mail}</li>
                <li>{phone}</li>
                <li>{description}</li>
            </ul>
        </article>
    )   
}

export default PlannerPage
